<template>

  <b-modal id="modal-addCodes" title="Add Codes" @ok="handleOk">
    <p>
      <b-container fluid>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="numberOfCodes"># Codes</label>
          </b-col>
          <b-col>
            <b-form-input id="numberOfCodes" v-model="numberOfCodes" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="product">Product</label>
          </b-col>
          <b-col>
            <b-form-select id="product" v-model="newCode.product" :options="options"></b-form-select>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="trial">Trial</label>
          </b-col>
          <b-col>
            <b-form-checkbox id="trial" v-model="trial"></b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="count">Activation Count</label>
          </b-col>
          <b-col>
            <b-form-input id="count" v-model="newCode.count" type="number" :disabled="trial"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="daysValid">Days Valid</label>
          </b-col>
          <b-col>
            <b-form-input id="daysValid" v-model="newCode.daysValid" type="number" :disabled="trial"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="user">User</label>
          </b-col>
          <b-col>
            <span id="user" disabled>{{ user ? user.email : "New"}}</span>
          </b-col>
        </b-row>
      </b-container>
    </p>
    <hr/>
    <p>
      <b-button size="sm" class="mb-2">
        <b-icon-clipboard @click="copyToClipBoard(codes.join('\n'))" />
      </b-button><br>
      <span v-for="code in codes" :key="code">
        {{ code }}<br>
      </span>
    </p>
  </b-modal>

</template>

<script>
import {generateActivationCode} from '@/code.js'
import {DataStore} from "@aws-amplify/datastore";
import {ActivationCode} from "@/models";

export default {
  name: "AddCodes",
  data() {
    return {
      numberOfCodes: 0,
      codes: [],
      options: [
        { value: "Standard", text: 'Standard' },
        { value: "Pro", text: 'Pro' },
        { value: "Pro Plus", text: 'Pro Plus' },
        { value: "Beta", text: 'Beta' },
      ],
      trial: false,
      newCode: {
        product: "Standard",
        features: [],
        count: 1,
        daysValid: 0,
      },
    }
  },
  async created() {
    this.numberOfCodes = 1
    this.codes.push(await this.generateUniqueCode())
  },
  computed: {
    user() {
      return this.$parent.user
    }
  },
  methods: {
    async handleOk() {
      let product = this.newCode.product
      if (this.trial) {
        if (product === "Standard") {
          product = "Trial"
        }
        if (product === "Pro") {
          product = "TrialPro"
        }
        if (product === "Pro Plus") {
          product = "TrialProPlus"
        }
        this.newCode.daysValid = 30
        this.newCode.count = 1
      }
      for (const code of this.codes) {
        const data = {
          features: [],
          count: parseInt(this.newCode.count),
          daysValid: parseInt(this.newCode.daysValid),
          product,
          code
        }
        if (this.user) {
          data.userID = this.user.id
        }
        const newCode = await DataStore.save(new ActivationCode(data))
        this.$parent.rows.push(newCode)
      }
      navigator.clipboard.writeText(this.codes.join("\n"))
    },
    copyToClipBoard(textToCopy){
      navigator.clipboard.writeText(textToCopy);
    },
    async generateUniqueCode() {
      let code = generateActivationCode()
      let exists
      do {
        exists = (await DataStore.query(ActivationCode)).filter(c => c.code === code)
      } while(exists.length > 0)
      return code
    }
  },
  watch: {
    async numberOfCodes() {
      for (let i=this.codes.length; i<this.numberOfCodes; i++) {
        this.codes.push(await this.generateUniqueCode())
      }
      if (this.numberOfCodes < this.codes.length) {
        this.codes.splice(this.numberOfCodes, this.codes.length - this.numberOfCodes)
      }
    }
  }

}
</script>

<style scoped>

</style>
