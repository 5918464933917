<template>
  <div>
    <b-button v-if="!visible" @click="visible = !visible">Users</b-button>

    <add-user/>

    <b-collapse id="collapse-user" v-model="visible">
      <b-card>
        <b-card-header>
          Users <b-button size="sm" class="ml-1" v-b-modal.modal-addUser>Add</b-button>
        </b-card-header>
        <b-card-body>
          <vue-good-table
              :pagination-options="{enabled: true,}"
              :columns="columns"
              :rows="rows"
              :sort-options="{ enabled: true, }"
              :search-options="{ enabled: true }"
              class="vgt-table condensed"
          >
            <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'email'">
            <a href="#" @click="load(props.row)">{{props.row.email}}</a>
          </span>
              <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
            </template>
          </vue-good-table>
        </b-card-body>
      </b-card>
    </b-collapse>

    <div v-if="user">
      <codes :user="user"/>
    </div>

  </div>

</template>

<script>
import { DataStore } from 'aws-amplify'
import { User} from "@/models";
import { VueGoodTable } from 'vue-good-table';
import Codes from "@/components/Codes";
import AddUser from "@/components/AddUser";

export default {
  name: "Users",
  components: {
    VueGoodTable,
    Codes,
    AddUser,
  },
  props: {
  },
  async created() {
    this.rows = await DataStore.query(User)
  },
  data() {
    return {
      visible: true,
      selected: null,
      user: null,
      userCodes: null,
      rows: [],
      columns: [
        {
          label: 'First Name',
          field: 'firstName',
        },
        {
          label: 'Name',
          field: 'lastName',
        },
        {
          label: 'Company',
          field: 'company',
        },
        {
          label: 'Email',
          field: 'email',
        },
      ],
    }
  },
  computed: {
  },
  methods: {
    load(user) {
      this.user = user
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
