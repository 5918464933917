<template>
  <div>
    <b-card>
      <b-card-header>
        Licenses
      </b-card-header>
      <b-card-body>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{enabled: true}"
            :sort-options="{ enabled: true, }"
            :search-options="{ enabled: true }"
            class="vgt-table condensed"
        >
          <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'hwcode'">
          <a href="#" @click="selected = props.row">{{props.row.hwcode}}</a>
        </span>
            <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>

    <b-card v-if="selected">
      <code>
        {{ selected.code }}
      </code>
    </b-card>
  </div>

</template>

<script>
import { DataStore } from 'aws-amplify'
import { License} from "@/models";
import { VueGoodTable } from 'vue-good-table';

export default {
  name: "Licenses",
  components: {
    VueGoodTable,
  },
  props: {
    code: null
  },
  data() {
    return {
      selected: null,
      rows: [],
      columns: [
        {
          label: 'HW-Code',
          field: 'hwcode',
        },
        {
          label: 'Issued',
          field: 'issuedAt',
        },
        {
          label: 'Valid',
          field: 'validTill',
        },
      ],
    }
  },
  async created() {
    await this.load()
  },
  async beforeUpdate() {
  },
  watch: {
    async code() {
      await this.load()
    }
  },
  methods: {
    async load() {
      if (this.code) {
        this.rows = (await DataStore.query(License)).filter(l => l.activationCode.id === this.code.id)
      } else {
        this.rows = await DataStore.query(License)
      }
    }
  }
}
</script>

<style scoped>

</style>
