import App from './App.vue'

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import {router} from "@/router";

import './app.scss'

import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import Vue from 'vue'

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.use(VueGoodTablePlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

Vue.config.ignoredElements = [/amplify-\w*/];

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
