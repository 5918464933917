<template>

  <b-modal id="modal-addUser" title="Add User" @ok="handleOk">
    <p>
      <b-container fluid>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="firstName">First Name</label>
          </b-col>
          <b-col>
            <b-form-input id="firstName" v-model="newUser.firstName"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="lastName">Last Name</label>
          </b-col>
          <b-col>
            <b-form-input id="lastName" v-model="newUser.lastName"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="company">Company</label>
          </b-col>
          <b-col>
            <b-form-input id="company" v-model="newUser.company"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col sm="4">
            <label for="email">Email</label>
          </b-col>
          <b-col>
            <b-form-input id="email" v-model="newUser.email" type="email"></b-form-input>
          </b-col>
        </b-row>
      </b-container>
    </p>
  </b-modal>

</template>

<script>
import {User} from "@/models";
import {DataStore} from "@aws-amplify/datastore";

export default {
  name: "AddUser",
  data() {
    return {
      newUser: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
      },
    }
  },
  methods: {
    async handleOk() {
      const user = await DataStore.save(new User(this.newUser))
      this.$parent.rows.push(user)
    }
  }
}
</script>

<style scoped>

</style>
