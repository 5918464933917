import VueRouter from 'vue-router'
import Codes from "@/components/Codes";
import Users from "@/components/Users";
import Vue from "vue";
import Import from "@/components/Import";

Vue.use(VueRouter)

const routes = [
    { path: '/codes', component: Codes },
    { path: '/users', component: Users },
    { path: '/import', component: Import },
]

export const router = new VueRouter({
    routes // short for `routes: routes`
})
