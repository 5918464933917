const VALID_CHARS = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ";

export function generateActivationCode() {
    var code = ""
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 4; j++) {
            code += VALID_CHARS.charAt(Math.floor(Math.random() * VALID_CHARS.length))
        }
        if (i < 2) code += "-";
    }
    return code
}
