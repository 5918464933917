<template>
  <div>

    <add-codes></add-codes>

    <b-button v-if="!visible" @click="visible = !visible">Codes</b-button>
    <b-collapse id="collapse-codes" v-model="visible">
      <b-card>
        <b-card-header>
          <span v-if="user">
            {{ user.firstName }} {{ user.lastName }}
            <b-button v-b-modal.modal-addCodes size="sm" class="ml-1">Add Codes</b-button>
          </span>
          <span v-else>
            Codes <b-button v-b-modal.modal-addCodes size="sm" class="ml-1">Add</b-button>
          </span>
        </b-card-header>
        <b-card-body>
          <vue-good-table
              v-if="loaded"
              :columns="columns"
              :rows="rows"
              :pagination-options="{enabled: true}"
              :sort-options="{ enabled: true, }"
              :search-options="{ enabled: true }"
              class="vgt-table condensed"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'code'">
                <a href="#" @click="select(props.row)">{{props.row.code}}</a>
              </span>
              <span v-else-if="props.column.field === 'count'">
                <b-form-input size="sm" v-model="props.row[props.column.field]"
                              type="number" @change="changeCell(props)"
                ></b-form-input>
              </span>
              <span v-else-if="props.column.field === 'available'">
                {{ available(props.row) }}
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>
        </b-card-body>
      </b-card>
    </b-collapse>

    <licenses v-if="selected" :code="selected" />

  </div>
</template>

<script>
import { DataStore } from 'aws-amplify'
import {ActivationCode, License, User} from "@/models";
import { VueGoodTable } from 'vue-good-table';
import Licenses from "@/components/Licenses";
import AddCodes from "@/components/AddCodes";

export default {
  name: "Codes",
  components: {
    VueGoodTable,
    Licenses,
    AddCodes,
  },
  props: {
    user: null,
  },
  data() {
    return {
      visible: true,
      loaded: false,
      selected: null,
      licenses: {},
      users: {},
      rows: [],
      columns: [
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Product',
          field: 'product',
        },
        {
          label: 'Count',
          field: 'count',
          type: 'number',
          width: "100px",
        },
        {
          label: 'Days',
          field: 'daysValid',
          type: 'number',
        },
        {
          label: 'Free',
          field: 'available',
          sortable: false,
          globalSearchDisabled: true,
        },
        {
          label: 'User',
          field: this.email,
        },
      ],
    }
  },
  async created() {
    const lics = await DataStore.query(License)
    lics.forEach(l => {
      if (!this.licenses[l.activationCode.id]) {
        this.licenses[l.activationCode.id] = []
      }
      this.licenses[l.activationCode.id].push(l)
    })

    const users = await DataStore.query(User)
    users.forEach(u => {
      this.users[u.id] = u
    })

    this.loaded = true
    await this.load()
  },
  async beforeUpdate() {
  },
  watch: {
    async user() {
      this.selected = null
      await this.load()
    }
  },
  methods: {
    async load() {
      if (this.user) {
        this.rows = (await DataStore.query(ActivationCode)).filter(a => a.userID === this.user.id)
      } else {
        this.rows = await DataStore.query(ActivationCode)
      }
      this.visible = true
    },
    async changeCell(props) {
      const code = ActivationCode.copyOf(await DataStore.query(ActivationCode, props.row.id), upd => {
        upd.count = parseInt(props.row.count)
      })
      await DataStore.save(code)
    },
    select(code) {
      this.selected = code
      this.visible = false
    },
    available(row) {
      return row.count - (this.licenses[row.id] ? this.licenses[row.id].length : 0)
    },
    email(row) {
      if(row.userID && this.users[row.userID]) {
        return this.users[row.userID].email
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
