// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { License, ActivationCode, User } = initSchema(schema);

export {
  License,
  ActivationCode,
  User
};