<template>
  <div id="app">
    <b-row v-if="authState !== 'signedin'">
      <b-col>
        <b-card>
          <b-card-header>
            You are signed out.
          </b-card-header>
          <b-card-body>
            <amplify-authenticator />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <router-link class="ml-1" to="/codes"><b-badge>Codes</b-badge></router-link>
        <router-link class="ml-1" to="/users"><b-badge>Users</b-badge></router-link>
        <router-link class="ml-1" to="/import"><b-badge>Import</b-badge></router-link>
      </b-col>
      <b-col md="2">
        <amplify-authenticator>
          <div v-if="authState === 'signedin' && user">
            <div class="text-right"><b-badge>{{user.attributes.email}}</b-badge></div>
          </div>
          <amplify-sign-out></amplify-sign-out>
        </amplify-authenticator>
      </b-col>
    </b-row>
    <b-row v-if="authState === 'signedin'">
      <b-col>
        <router-view></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
  name: 'App',
  components: {
  },
  async created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
