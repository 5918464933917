<template>
  <div>
    <b-card>
      <b-card-header>
        Import
      </b-card-header>
      <b-card-body>
        <input type="file" @change="uploadFile" ref="file" />
        <button @click="submitFile">Import</button>
        <p>
          {{ progress }}% {{ message }}
        </p>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {ActivationCode, License, User} from "@/models";
import { DataStore } from 'aws-amplify'

export default {
  name: "Import",
  data() {
    return {
      importFile: null,
      message: '',
      progress: 0,
      toCreate: {
        codes: [],
        users: [],
        licenses: []
      },
      toUpdate: {
        codes: [],
        users: [],
        licenses: []
      },
    }
  },
  methods: {
    uploadFile() {
      this.importFile = this.$refs.file.files[0]
    },
    async submitFile() {
      const content = await this.importFile.text()
      const json = JSON.parse(content)
      const target = json.length

      var i = 0
      for (const c of json) {
        i++
        this.progress = Math.round(i / target * 100)
        let code = (await DataStore.query(ActivationCode)).filter(oc => oc.code === c.code).pop()

        if (code) {

          let user
          if (c.user) {
            user = (await DataStore.query(User)).filter(u => u.email === c.user.email).pop()
            if (!user) {
              this.message = `creating user ${c.user.email}`
              user = await DataStore.save(new User({
                email: c.user.email,
                firstName: c.user.firstname,
                lastName: c.user.lastname
              }))
            } else {
              this.message = `updating user ${c.user.email}`
              user = await DataStore.save(User.copyOf(user, (upd) => {
                upd.email = c.user.email
                upd.firstName = c.user.firstname
                upd.lastName = c.user.lastname
              }))
            }
          }

          this.message = `updating code ${code.code}`
          code = await DataStore.save(ActivationCode.copyOf(code, upd => {
            upd.product = c.product
            upd.count = c.count
            upd.code = c.code
            upd.daysValid = c.daysvalid
            if (user) {
              upd.userID = user.id
            }
          }))

          for (const l of c.licenses) {
            let license = (await DataStore.query(License)).filter(ll => {
              return ll.code === l.code
              && ll.hwcode === l.hwcode
              && new Date(ll.issuedAt).toISOString() === new Date(l.issuedat).toISOString()
            }).pop()
            if (!license) {
              this.message = `creating license ${l.hwcode} for ${code.id}`
              await DataStore.save(new License({
                activationCode: code,
                code: l.code,
                hwcode: l.hwcode,
                issuedAt: new Date(l.issuedat).toISOString(),
                validTill: (l.validtill && l.validtill !== 'nan') ? new Date(l.validtill).toISOString() : null,
              }))
            } else {
              this.message = `license ${l.hwcode} for ${code.id} exists`
            }
          }

        } else {

          let user
          if (c.user) {
            user = (await DataStore.query(User)).filter(u => u.email === c.user.email).pop()
            if (!user) {
              this.message = `creating user ${c.user.email}`
              user = await DataStore.save(new User({
                email: c.user.email,
                firstName: c.user.firstname,
                lastName: c.user.lastname
              }))
            } else {
              this.message = `updating user ${c.user.email}`
              user = await DataStore.save(User.copyOf(user, (upd) => {
                upd.email = c.user.email
                upd.firstName = c.user.firstname
                upd.lastName = c.user.lastname
              }))
            }
          }

          this.message = `creating code ${c.code}`
          const newCode = await DataStore.save(new ActivationCode({
            product: c.product,
            count: c.count,
            code: c.code,
            features: [],
            daysValid: c.daysvalid,
            userID: user ? user.id : null
          }))

          for (const l of c.licenses) {
            this.message = `creating license ${l.hwcode} for ${newCode.id}`
            await DataStore.save(new License({
              code: l.code,
              hwcode: l.hwcode,
              issuedAt: new Date(l.issuedat).toISOString(),
              validTill: (l.validtill && l.validtill !== 'nan') ? new Date(l.validtill).toISOString() : null,
              activationCode: newCode
            }))
          }

        }
      }


    },
  }
}
</script>

<style scoped>

</style>
